import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/user.context";
import { UIContext } from "../../context/UI.context";
import { ReactComponent as AutoAppLogoClean } from "../../assets/AutoappLogoClean.svg";
import { signInWithEmail } from "../../utils/firebase.utils";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [result, setResult] = useState({ success: "yes" });

  const { setCurrentUser } = useContext(AuthContext);
  const { loading, setLoading } = useContext(UIContext);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {

  }, []);
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const authUser = await signInWithEmail(email, password);
    if (authUser.success === "yes") {
      setResult({ success: "yes" });
      setCurrentUser(authUser);
      navigate("/");
      setLoading(false);
    } else {
      setResult(authUser);
      setLoading(false);
    }
  };

  return (
    <Container>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minWidht: 400,
        }}
      >
        <AutoAppLogoClean alt="close bubble" height="100px" width="100px" />
        <Typography component="h1" variant="h5">
          AutoApp - Subastas
        </Typography>
        <Typography component="h3" variant="h5">
          Iniciar sesión
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            mt: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minWidth: 400,
          }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Usuario"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleEmailChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handlePasswordChange}
          />

          {!loading && (
            <Button
              type="submit"
              variant="contained"
              size="large"
              fullWidth={true}
              sx={{ mt: 2, mb: 4 }}
            >
              iniciar Sesión
            </Button>
          )}
          {result.success === "no" && (
            <Alert severity="error">
              <AlertTitle>Se produjo un error</AlertTitle>
              <strong>{result.message}</strong>
            </Alert>
          )}
          {loading && <CircularProgress color="primary" sx={{ mt: 2}}/>}
        </Box>
      </Box>
    </Container>
  );
};

export default SignIn;
