import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getActiveAuctions } from '../../utils/firebase.utils';
import { es } from 'date-fns/locale';


const styles = {
    input: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '1rem',
        padding: '10px 12px',
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        boxSizing: 'border-box',
        '&:hover': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
        },
        '&:focus': {
            borderColor: '#3f51b5',
            boxShadow: '0 0 0 2px rgba(63,81,181,0.2)',
        }
    },
    // Puedes definir más estilos aquí según sea necesario
};

const AuctionEndTimePicker = ({ onDateTimeSelect }) => {
    const [endDate, setEndDate] = useState(new Date());
    const [excludedTimes, setExcludedTimes] = useState([]);

    useEffect(() => {
        const fetchExcludedTimes = async () => {
            const activeAuctionsQuery = await getActiveAuctions();
            const activeAuctions = activeAuctionsQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))     
            const times = activeAuctions.map(auction => new Date(auction.finishTime));
            console.log(times)
            setExcludedTimes(times);
        };
        fetchExcludedTimes();
    }, []);

    const isTimeSelectable = (time) => {
        // Comprobar si el tiempo está dentro de los excluidos
        const timeStr = time.toISOString();
        return !excludedTimes.some(excludedTime => excludedTime.toISOString() === timeStr);
    };

    return (
        <DatePicker
            selected={endDate}
            onChange={date => {
                setEndDate(date);
                onDateTimeSelect(date.getTime());
            }}
            dateFormat="dd MMM yyyy HH:mm:ss"
            showTimeSelect
            filterTime={isTimeSelectable}
            timeFormat="HH:mm"
            timeIntervals={30}
            timeCaption="Hora"
            locale={es}
            className="custom-datepicker"
            calendarClassName="custom-calendar"
            wrapperClassName="datepicker-wrapper"
            popperClassName="custom-popper"
            customInput={<input style={styles.input} />}
        />
    );
};

export default AuctionEndTimePicker;
