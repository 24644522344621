import { createContext, useState } from "react";

export const AuctionContext = createContext({
  currentAuctions: null,
  setCurrentAuctions: () => {},
});

export const AuctionsProvider = ({ children }) => {
  const [currentAuctions, setCurrentAuctions] = useState(null);
  const value = { currentAuctions, setCurrentAuctions };

  return (
    <AuctionContext.Provider value={value}>{children}</AuctionContext.Provider>
  );
};
