import { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../context/user.context";
import { getAllOffers, getAuction } from "../../utils/firebase.utils";

import { UIContext } from "../../context/UI.context";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Typography } from "@mui/material";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0b7c9e",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AuctionOffers = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [activeAuction, setActiveAuction] = useState(null);
  const [offers, setOffers] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const { loading, setLoading } = useContext(UIContext);

  useEffect(() => {
    if (currentUser === null) {
      navigate("/sign-in");
    }
  });

  useEffect(() => {
    if (currentUser === null) {
      navigate("/sign-in");
    } else {
      const currentAuction = async () => {
        setLoading(true);
        const response = await getAuction(params.plate);
        setActiveAuction(response.data());
        setLoading(false);
      };
      currentAuction();
    }
  }, [currentUser, navigate, params.plate, setLoading]);

  useEffect(() => {
    if (currentUser === null) {
      navigate("/sign-in");
    } else {
      const currentOffers = async () => {
        setLoading(true);
        const offersQuery = await getAllOffers(params.plate);
        setOffers(
          offersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
        setLoading(false);
      };
      currentOffers();
    }
  }, [currentUser, setLoading, navigate, params.plate]);

  return (
    <Stack spacing={2} sx={{ ml: 3, mr: 3 }}>
      {activeAuction && (
        <Typography component="h3" color="#0b7c9e" fontWeight={600}>
          Resultado Final: {activeAuction.brand} {activeAuction.model} -{" "}
          {activeAuction.plate} - precio base ={" "}
          {Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "ARS",
            maximumSignificantDigits: 4,
          }).format(activeAuction.price)}
        </Typography>
      )}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Nombre</StyledTableCell>
              <StyledTableCell align="left">Telefono</StyledTableCell>
              <StyledTableCell align="left">Oferta</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              offers &&
              offers.map((row) => {
                const profit = activeAuction.highestOffer - activeAuction.price;
                const profitPercentage =
                  (activeAuction.highestOffer - activeAuction.price) /
                  activeAuction.price;
                if (row.userEmail === activeAuction.winner) {
                  return (
                    <StyledTableRow
                      key={row.userEmail}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {row.userName}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.userPhone}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {Intl.NumberFormat("es-AR", {
                          style: "currency",
                          currency: "ARS",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        }).format(row.amount)}{" "}
                        ({"+ "}
                        {Intl.NumberFormat("es-AR", {
                          style: "currency",
                          currency: "ARS",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        }).format(profit)}{" "}
                        {" - "} ↑{" "}
                        {Intl.NumberFormat("default", {
                          style: "percent",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        }).format(profitPercentage)}
                        )
                      </StyledTableCell>
                      <StyledTableCell align="center">Ganador</StyledTableCell>
                    </StyledTableRow>
                  );
                } else {
                  return (
                    <StyledTableRow
                      key={row.userEmail}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <StyledTableCell align="left">
                        {row.userName}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.userPhone}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {Intl.NumberFormat("es-AR", {
                          style: "currency",
                          currency: "ARS",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        }).format(row.amount)}
                      </StyledTableCell>
                      <StyledTableCell align="left"></StyledTableCell>
                    </StyledTableRow>
                  );
                }
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default AuctionOffers;
