import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { CircularProgress } from "@mui/material";

import { useContext, useState } from "react";
import { createNewUser } from "../../utils/firebase.utils";

import { AuthContext } from "../../context/user.context";
import { UIContext } from "../../context/UI.context";

const NewUserForm = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const { loading, setLoading } = useContext(UIContext);
  const [result, setResult] = useState({ success: "pending" });
  const [formFields, setFormFields] = useState({
    name: "",
    phone: "",
    adress: "",
    city:'',
    personalId: "",
    dealer: currentUser.dealership,
    email: "",
    password: "",
    confirmPassword: "",
  });
  
  const { name, phone, adress, city, personalId, email, password, confirmPassword } =
    formFields;

  const resetFormFields = () => {
    setFormFields({
      name: "",
      phone: "",
      adress: "",
      city: '',
      personalId: "",
      dealer: currentUser.dealership,
      email: "",
      password: "",
      confirmPassword: "",
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      alert("el password no coincide");
      return;
    }
    setLoading(true);
    const response = await createNewUser(formFields);
    if (response.success === "yes") {
      setResult({ success: "yes" });
    }
    setResult(response);
    setLoading(false);
    resetFormFields();
  };


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" color="#007ca2">
          Nuevo Usuario
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            mt: 3,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="Nombre"
                required
                fullWidth
                id="firstName"
                autoFocus
                label="Nombre y Apellido"
                type="text"
                onChange={handleChange}
                name="name"
                value={name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Dirección"
                type="text"
                required
                onChange={handleChange}
                name="adress"
                value={adress}
                autoComplete="Direccion"
                fullWidth
                id="adress"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Localidad"
                type="text"
                required
                onChange={handleChange}
                name="city"
                value={city}
                autoComplete="Localidad"
                fullWidth
                id="city"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Teléfono"
                type="number"
                required
                onChange={handleChange}
                name="phone"
                value={phone}
                fullWidth
                id="phone"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Documento o Cuit"
                type="number"
                required
                onChange={handleChange}
                name="personalId"
                value={personalId}
                fullWidth
                id="personalId"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                type="email"
                fullWidth
                required
                onChange={handleChange}
                name="email"
                value={email.toLocaleLowerCase()}
                id="email"
                autoComplete='off'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                type="text"
                required
                onChange={handleChange}
                name="password"
                value={password}
                fullWidth
                autoComplete='off'
              />
            </Grid>{" "}
            <Grid item xs={12}>
              <TextField
                label="Confirmar Password"
                type="text"
                required
                onChange={handleChange}
                name="confirmPassword"
                value={confirmPassword}
                fullWidth
                id="confirmPassword"
                autoComplete='off'
              />
            </Grid>
          </Grid>
          {loading && <CircularProgress color="primary" />}
          {result.success === "no" && (
            <Alert severity="error">
              <AlertTitle>Se produjo un error</AlertTitle>
              <strong>{result.error}</strong>
            </Alert>
          )}
          {result.success === "yes" && (
            <Alert
              action={
                <Button
                  color="inherit"
                  size="medium"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Volver
                </Button>
              }
            >
              Usuario creado con éxito
            </Alert>
          )}
          {!loading && (
            <Button
              sx={{ mt: 3, mb: 2 }}
              variant="contained"
              type="submit"
              fullWidth
            >
              Crear
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default NewUserForm;
