import dayjs from "dayjs";

export const getRemainingTime = (timeStamp) => {
  const timeStampFinish = dayjs(timeStamp);
  const timeStampNow = dayjs();
  if (timeStampFinish.isBefore(timeStampNow)) {
    return false;
  }

  const padNumber = (number, minLength) => {
    const numberString = number.toString();
    if (numberString.length >= minLength) return numberString;
    return "0".repeat(minLength - numberString.length) + numberString;
  };

  const getRemainingSeconds = (timeStampNow, timeStampFinish) => {
    const seconds = timeStampFinish.diff(timeStampNow, "seconds") % 60;
    return padNumber(seconds, 2);
  };
  const getRemainingMinutes = (timeStampNow, timeStampFinish) => {
    const minutes = timeStampFinish.diff(timeStampNow, "minutes") % 60;
    return padNumber(minutes, 2);
  };
  const getRemainingHours = (timeStampNow, timeStampFinish) => {
    const hours = timeStampFinish.diff(timeStampNow, "hours") % 24;
    return padNumber(hours, 2);
  };
  const getRemainingDays = (timeStampNow, timeStampFinish) => {
    const days = timeStampFinish.diff(timeStampNow, "days");
    return padNumber(days, 2);
  };

  return {
    seconds: getRemainingSeconds(timeStampNow, timeStampFinish),
    minutes: getRemainingMinutes(timeStampNow, timeStampFinish),
    hours: getRemainingHours(timeStampNow, timeStampFinish),
    days: getRemainingDays(timeStampNow, timeStampFinish),
  };
};
