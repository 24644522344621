import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onSnapshot, query, where, collection } from "firebase/firestore";
import { AuthContext } from "../../context/user.context";
import {
  getActiveAuctions,
  getAllAuctions,
  db,
} from "../../utils/firebase.utils";
import { UIContext } from "../../context/UI.context";
import { AuctionContext } from "../../context/auction.context";
import Countdown from "../../components/countdown/countdown.component";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#f7f9fc",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const AUCTIONS_PER_PAGE = 10;

const AuctionList = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const { currentAuctions, setCurrentAuctions } = useContext(AuctionContext);
  const [activeAuctions, setActiveAuctions] = useState([]);
  const [finishedAuctions, setFinishedAuctions] = useState([]);
  const { loading, setLoading } = useContext(UIContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const now = new Date().getTime();

  useEffect(() => {
    if (currentUser === null) {
      navigate("/sign-in");
    }
  });

  useEffect(() => {
    if (finishedAuctions.length > 0) {
      setTotalPages(Math.ceil(finishedAuctions.length / AUCTIONS_PER_PAGE));
    }
  }, [finishedAuctions]);

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  const getCurrentPageAuctions = () => {
    const startIndex = (currentPage - 1) * AUCTIONS_PER_PAGE;
    const endIndex = startIndex + AUCTIONS_PER_PAGE;
    return finishedAuctions.slice(startIndex, endIndex);
  };

  useEffect(() => {
    if (currentUser === null) {
      return;
    }
    const getAuctions = async () => {
      setLoading(true);
      const activeAuctionsQuery = await getActiveAuctions();
      setActiveAuctions(
        activeAuctionsQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      const auctionsQuery = await getAllAuctions(currentUser.dealership);
      setCurrentAuctions(
        auctionsQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
      setLoading(false);
    };
    getAuctions();
    const now = new Date().getTime();
    const unsubscribe = onSnapshot(
      query(collection(db, "auctions"), where("finishTime", ">", now)),
      (querrySnapshot) =>
        setActiveAuctions(
          querrySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
    );
    return () => unsubscribe;
  }, [setCurrentAuctions, setLoading, currentUser]);

  useEffect(() => {
    const now = new Date().getTime();
    if (currentAuctions) {
      const finishedAuctionsFiltered = currentAuctions.filter(
        (auctions) => auctions.finishTime < now
      );
      setFinishedAuctions(
        finishedAuctionsFiltered.sort((b, a) => a.finishTime - b.finishTime)
      );
    }
  }, [currentAuctions, setFinishedAuctions]);

  return (
    <Stack spacing={2} sx={{ pl: 3, pr: 3 }}>
      <Item>
        <TableContainer>
          <Typography
            component="h2"
            color="primary"
            fontWeight={700}
            sx={{ m: 2 }}
          >
            Subastas Activas
          </Typography>
          <Table sx={{ minWidth: 700 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Imagen</TableCell>
                <TableCell align="left">Patente</TableCell>
                <TableCell align="left">Marca</TableCell>
                <TableCell align="left">Modelo</TableCell>
                <TableCell align="left">Submodelo</TableCell>
                <TableCell align="left">Año</TableCell>
                <TableCell align="left">Kms</TableCell>
                <TableCell align="left">Ubicación</TableCell>
                <TableCell align="left">Precio inicial</TableCell>
                <TableCell lign="left">Oferta más Alta</TableCell>
                <TableCell lign="left">Visualizaciones</TableCell>
                <TableCell align="center">Tiempo restante</TableCell>
                <TableCell align="center">Acción</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading === false &&
                currentAuctions &&
                activeAuctions &&
                activeAuctions.map((row) => {
                  const profitPercentage =
                    (row.highestOffer - row.price) / row.price;
                  if (row.finishTime > now) {
                    return (
                      <TableRow
                        key={row.plate}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {" "}
                        <TableCell>
                          <Avatar
                            alt="front"
                            src={row.frontURL}
                            sx={{ width: 60, height: 60 }}
                          />
                        </TableCell>
                        <TableCell align="left">{row.plate}</TableCell>
                        <TableCell component="th" scope="row">
                          {row.brand}
                        </TableCell>
                        <TableCell align="left">{row.model}</TableCell>
                        <TableCell align="left">{row.subModel}</TableCell>
                        <TableCell align="left">{row.year}</TableCell>
                        <TableCell align="left">
                          {Intl.NumberFormat("es-AR").format(row.kms)}
                        </TableCell>
                        <TableCell align="left">{row.dealership}</TableCell>
                        <TableCell align="left">
                          {" "}
                          {Intl.NumberFormat("es-AR", {
                            style: "currency",
                            currency: "ARS",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          }).format(row.price)}
                        </TableCell>
                        <TableCell align="left">
                          {" "}
                          {Intl.NumberFormat("es-AR", {
                            style: "currency",
                            currency: "ARS",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          }).format(row.highestOffer)}{" "}
                          (↑{" "}
                          {Intl.NumberFormat("default", {
                            style: "percent",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                          }).format(profitPercentage)}
                          )
                        </TableCell>
                        <TableCell align="center">
                        {row.views}
                        </TableCell>
                        <TableCell align="center">
                          <Countdown finishTime={row.finishTime} />
                        </TableCell>
                        <TableCell align="center">
                          {row.dealership === currentUser.dealership && (
                            <Button
                              size="small"
                              onClick={() => {
                                navigate(`/modify-auction/${row.plate}`);
                              }}
                            >
                              Modificar
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  } else {
                    return (
                      <TableRow>
                        <TableCell>No hay subastas Activas</TableCell>
                      </TableRow>
                    );
                  }
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Item>
      <Item>
        <TableContainer>
          <Typography
            component="h3"
            color="#0b7c9e"
            fontWeight={600}
            sx={{ m: 2 }}
          >
            Subastas Finalizadas
          </Typography>
          <Table sx={{ minWidth: 700 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Imagen</TableCell>
                <TableCell align="left">Patente</TableCell>
                <TableCell align="left">Marca</TableCell>
                <TableCell align="left">Modelo</TableCell>
                <TableCell align="left">Submodelo</TableCell>
                <TableCell align="left">Año</TableCell>
                <TableCell align="left">Kms</TableCell>
                <TableCell align="left">Ubicación</TableCell>
                <TableCell lign="left">Visualizaciones</TableCell>
                <TableCell align="center">Tiempo restante</TableCell>
                <TableCell align="center">Acción</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading === false &&
                currentAuctions &&
                finishedAuctions &&
                getCurrentPageAuctions().map((row) => {
                  if (row.finishTime < now) {
                    return (
                      <TableRow
                        key={row.plate}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Avatar
                            alt="front"
                            src={row.frontURL}
                            sx={{ width: 60, height: 60 }}
                          />
                        </TableCell>
                        <TableCell align="left">{row.plate}</TableCell>
                        <TableCell component="th" scope="row">
                          {row.brand}
                        </TableCell>
                        <TableCell align="left">{row.model}</TableCell>
                        <TableCell align="left">{row.subModel}</TableCell>
                        <TableCell align="left">{row.year}</TableCell>
                        <TableCell align="left">
                          {Intl.NumberFormat("es-AR").format(row.kms)}
                        </TableCell>
                        <TableCell align="left">{row.location}</TableCell>
                        <TableCell align="center">
                          {row.views}
                        </TableCell>
                        <TableCell align="center">
                          <Countdown finishTime={row.finishTime} />
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            size="small"
                            onClick={() => {
                              navigate(`/auction-offers/${row.plate}`);
                            }}
                          >
                            Resultado
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  } else {
                    return (
                      <TableRow>
                        <TableCell>No hay subastas Finalizadas</TableCell>
                      </TableRow>
                    );
                  }
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
          color="primary"
        />
      </Item>
    </Stack>
  );
};

export default AuctionList;
