import { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";

import { ReactComponent as AutoAppLogo } from "../../assets/autoApp-logo.svg";
import { AuthContext } from "../../context/user.context";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1, mb: 3 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              navigate("/");
            }}
          >
            <HomeIcon />
          </IconButton>
          <Box sx={{ height: "100%", width: 60 }}>
            <AutoAppLogo />
          </Box>
          {currentUser && (
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {currentUser.dealership}
            </Typography>
          )}
          <Button
            color="inherit"
            onClick={() => {
              navigate("/new-user");
            }}
          >
            Nuevo Reventa
          </Button>
          <Button
            color="inherit"
            onClick={() => {
              navigate("/new-auction");
            }}
          >
            Nueva Subasta
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavBar;
