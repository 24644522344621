import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import { AuthContext } from "../../context/user.context";
import NavBar from "../../components/navigation-bar/nav-bar.component";

const Home = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (currentUser === null) {
      navigate("/sign-in");
    }
  }, [currentUser, navigate]);
  return (
    <div>
      <NavBar />
      <Outlet />
    </div>
  );
};

export default Home;
