import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import { AuthContext } from "../../context/user.context";
import { AuctionContext } from "../../context/auction.context";
import ModifyAuctionForm from "../../components/modify-auction-form/modify-auction-form";

const ModifyAuction = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [activeAuction, setActiveAuction] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const { currentAuctions } = useContext(AuctionContext);

  useEffect(() => {
    if (currentUser === null || currentAuctions === null) {
      navigate("/sign-in");
    } else {
      const currentAuction = currentAuctions.filter(
        (auction) => auction.plate === params.plate
      );
      setActiveAuction(currentAuction[0]);
    }
  }, [currentAuctions, currentUser, navigate, params.plate]);

  return (
    <div className="form-container">
      {activeAuction !== null && (
        <ModifyAuctionForm auctionData={activeAuction} />
      )}
    </div>
  );
};

export default ModifyAuction;
