import { useNavigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import { AuthContext } from "../../context/user.context";
import NewAuctionForm from "../../components/new-auction-form/add-auction-form.component";

const NewAuction = () => {
  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);
  useEffect(() => {
    if (currentUser === null) {
      navigate("/sign-in");
    }
  }, [currentUser, navigate]);

  return (
    <div className="form-container">{currentUser && <NewAuctionForm />}</div>
  );
};

export default NewAuction;
