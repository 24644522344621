import React from 'react';

import { useState, useEffect } from "react";

import { getRemainingTime } from "./countdown-helper";

import "./countdown.styles.scss";

const defaultRemainingTime = {
  seconds: "00",
  minutes: "00",
  hours: "00",
  days: "00",
};

const Countdown = (props) => {
  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(props.finishTime);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [props.finishTime]);

  const updateRemainingTime = (timeStamp) => {
    setRemainingTime(getRemainingTime(timeStamp));
  };

  return (
    <div className="container">
      {!remainingTime && <span className="finish-text">Finalizada</span>}
      {remainingTime && (
        <React.Fragment>
          <div className="time-block">
            <span className="time-number">{remainingTime.days}</span>
            <span className="time-label">D</span>
          </div>
          <div className="time-block">
            <span className="time-number">{remainingTime.hours}</span>
            <span className="time-label">H</span>
          </div>
          <div className="time-block">
            <span className="time-number">{remainingTime.minutes}</span>
            <span className="time-label">M</span>
          </div>
          <div className="time-block">
            <span className="time-number">{remainingTime.seconds}</span>
            <span className="time-label">S</span>
          </div>
        </React.Fragment>
      )}
    </div>
  );

};
export default Countdown;
