import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";

import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB-eO9k0lBzMjU76SppVtPavJFgA81vZaM",
  authDomain: "autoappsistema.firebaseapp.com",
  projectId: "autoappsistema",
  storageBucket: "autoappsistema.appspot.com",
  messagingSenderId: "307904698645",
  appId: "1:307904698645:web:e4ba69c2a7f963a526f11c",
  measurementId: "G-M5B8W3SDYK",
};

initializeApp(firebaseConfig);
export const db = getFirestore();
export const auth = getAuth();
const storage = getStorage();

//Notifications for new auction
export const sendNotifications = (pushTokens) => {
  pushTokens.forEach((pushToken) => {
    fetch("https://exp.host/--/api/v2/push/send", {
      method: "POST",
      body: JSON.stringify({
        to: pushToken,
        title: "Nueva subasta",
        body: "Existe una nueva subasta disponible",
      }),
    });
  });
};

// get all user push tokens
export const getUsersPushToken = async () => {
  const pushTokens = [];
  const auctionsRef = collection(db, "users");
  const q = query(auctionsRef, where("expoPushToken", "!=", null));
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach((doc) =>
      pushTokens.push(doc.data().expoPushToken)
    );
  } catch (error) {
    return { success: "no", massage: error.message };
  }
  return pushTokens;
};
//

//Login component only for users in dealership list
export const signInWithEmail = async (email, password) => {
  if (!email || !password) {
    return {
      success: "no",
      message: "complete su usuario y contraseña para continuar",
    };
  }

  const userDocRef = doc(db, "dealership", email);
  const userSnapshot = await getDoc(userDocRef);

  if (userSnapshot.exists()) {
    try {
      const authenticationData = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const dealership = userSnapshot.data().displayName;
      const authToken = authenticationData.user.accessToken;
      return { success: "yes", dealership, authToken };
    } catch (error) {
      if (error.message === "Firebase: Error (auth/wrong-password).") {
        return {
          success: "no",
          message: "Contraseña incorrecta",
        };
      } else {
        return {
          success: "no",
          message: "Se produjo un error",
          error: error.message,
        };
      }
    }
  } else {
    return { success: "no", message: "usuario inexistente" };
  }
};
//

//New user creation
export const createNewUser = async (newUserData) => {
  if (!newUserData) return;

  const userDocRef = doc(db, "users", newUserData.email);

  const userSnapshot = await getDoc(userDocRef);

  if (!userSnapshot.exists()) {
    const { adress, city, dealer, personalId, email, name, password, phone } =
      newUserData;
    const created = new Date();
    try {
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (error) {
      return {
        message: "se produjo un error",
        error: error.message,
        success: "no",
      };
    }
    try {
      await setDoc(userDocRef, {
        adress,
        city,
        dealer,
        personalId,
        email,
        name,
        password,
        phone,
        created,
      });
    } catch (error) {
      return {
        success: "no",
        message: "error escribiendo en la base",
        error: error.message,
      };
    }
    return { success: "yes", message: "Usuario creado con éxito" };
  } else {
    return { success: "yes", message: "Email ya está en uso" };
  }
};
//

//Create a new auction
export const createNewAuction = async (newAuctionData) => {
  const auctionDocRef = doc(db, "auctions", newAuctionData.plate);
  const auctionSnapshot = await getDoc(auctionDocRef);
  if (!auctionSnapshot.exists()) {
    const {
      brand,
      model,
      subModel,
      year,
      dealership,
      kms,
      location,
      price,
      front,
      pictures,
      auctionEndTime,
      plate,
      apprasing,
    } = newAuctionData;

    const created = new Date().getTime();
    try {
      // Sube la imagen frontal y obtiene su URL
      const frontURL = await uploadImageAndGetURL(front, `images/${dealership}/${plate}/pictures/front`);

      // Sube todas las imágenes adicionales y obtiene sus URLs
      const picturesURLs = await Promise.all(pictures.map(image => uploadImageAndGetURL(image, `images/${dealership}/${plate}/pictures/${Math.random(1000)}`)));

      // Sube las imágenes de tasación y obtiene sus URLs
      const apprasingURLs = await Promise.all(apprasing.map(image => uploadImageAndGetURL(image, `images/${dealership}/${plate}/apprasing/${Math.random(1000)}`)));

      // Una vez que todas las imágenes han sido subidas con éxito, crea el documento
      await setDoc(auctionDocRef, {
        brand,
        dealership,
        kms,
        location,
        finishTime: auctionEndTime,
        model,
        plate,
        price,
        highestOffer: price,
        subModel,
        year,
        created,
        frontURL,
        picturesURL: picturesURLs,
        apprasingURL: apprasingURLs,
      });

      return { success: "yes", message: "subasta creada" };
    } catch (error) {
      console.error("Error uploading images or creating auction:", error);
      return {
        success: "no",
        message: "No se pudo crear la subasta debido a un error en la subida de imágenes",
        error: error.message,
      };
    }
  } else {
    return {
      success: "no",
      message: "Un vehículo con esta patente ya fue subastado",
    };
  }
};

async function uploadImageAndGetURL(image, path) {
  if (!image) {
    // Si no hay imagen, no intentes subirla
    return null;
  }

  try {
    const imageRef = ref(storage, path);
    const snapshot = await uploadBytes(imageRef, image);
    return getDownloadURL(snapshot.ref);
  } catch (error) {
    console.error("Error uploading image to Firebase Storage:", error);
    throw error; // Puedes manejar el error de manera más específica si lo necesitas
  }
}


//Modify an auction
export const modifyAuction = async (modifiedAuctionData) => {
  const auctionDocRef = doc(db, "auctions", modifiedAuctionData.plate);
  const auctionSnapshot = await getDoc(auctionDocRef);
  if (auctionSnapshot.exists()) {
    const {
      brand,
      model,
      subModel,
      year,
      kms,
      location,
      date,
      finish,
      front, // Nueva imagen frontal
      pictures, // Nuevas imágenes adicionales
      apprasing, // Nuevas imágenes de tasación
    } = modifiedAuctionData;

    const modified = new Date().getTime();
    const multiplier = date * 60 * 60 * 1000;
    const finishTime = finish + multiplier;
  
    try {
      // Subir y reemplazar imágenes si se proporcionan
      const updates = { brand, kms, model, subModel, year, location, finishTime, modified };

      if (front) {
        const frontURL = await uploadImageAndGetURL(front, `images/${modifiedAuctionData.dealership}/${modifiedAuctionData.plate}/pictures/front`);
        updates.frontURL = frontURL;
      }

      if (pictures && pictures.length) {
        const picturesURLs = await Promise.all(pictures.map(image => uploadImageAndGetURL(image, `images/${modifiedAuctionData.dealership}/${modifiedAuctionData.plate}/pictures/${Math.random(1000)}`)));
        updates.picturesURL = picturesURLs;
      }

      if (apprasing && apprasing.length) {
        const apprasingURLs = await Promise.all(apprasing.map(image => uploadImageAndGetURL(image, `images/${modifiedAuctionData.dealership}/${modifiedAuctionData.plate}/apprasing/${Math.random(1000)}`)));
        updates.apprasingURL = apprasingURLs;
      }

      // Actualizar documento con la información modificada y las URLs de imágenes
      await setDoc(auctionDocRef, updates, { merge: true });

      return { success: "yes", message: "Subasta modificada con éxito" };
    } catch (error) {
      console.error("Error al modificar la subasta:", error);
      return { success: "no", message: "No se pudo modificar la subasta", error: error.message };
    }
  } else {
    return { success: "no", message: "La subasta no existe" };
  }
};
//

/*export const modifyAuction = async (modifiedAuctionData) => {
  const auctionDocRef = doc(db, "auctions", modifiedAuctionData.plate);
  const auctionSnapshot = await getDoc(auctionDocRef);
  if (auctionSnapshot.exists()) {
    const { brand, model, subModel, year, kms, location, date, finish } =
      modifiedAuctionData;

    const modified = new Date().getTime();
    const multiplier = date * 60 * 60 * 1000;
    const finishTime = finish + multiplier;
    try {
      await setDoc(
        auctionDocRef,
        {
          brand,
          kms,
          model,
          subModel,
          year,
          location,
          finishTime,
          modified,
        },
        { merge: true }
      );
      return { success: "yes", message: "Subasta modificada con éxito" };
    } catch (error) {
      return { success: "no", message: "no se pudo modificar la subasta" };
    }
  }
};*/
//

//get a specific user from the user collection
export const getUser = async (userEmail) => {
  if (userEmail === null) {
    return;
  }
  const userDocRef = doc(db, "users", userEmail);
  try {
    const userSnapshot = await getDoc(userDocRef);
    return userSnapshot;
  } catch (error) {
    return { success: "no", message: error.message };
  }
};
//

// get a specific auction from the auctions collection
export const getAuction = async (plate) => {
  if (plate === null) {
    return;
  }
  const auctionDocRef = doc(db, "auctions", plate);
  try {
    const auctionSnapshot = await getDoc(auctionDocRef);
    return auctionSnapshot;
  } catch (error) {
    return { success: "no", message: error.message };
  }
};

//get all auctions for a specific dealership
export const getAllAuctions = async (dealership) => {
  if (dealership === null) {
    return;
  }
  const auctionsRef = collection(db, "auctions");
  const q = query(
    auctionsRef,
    where("dealership", "==", dealership),
    orderBy("finishTime")
  );
  try {
    const querySnapshot = await getDocs(q);
    return querySnapshot;
  } catch (error) {
    return { success: "no", massage: error.message };
  }
};
//

//get all active auctions
export const getActiveAuctions = async () => {
  const auctionsRef = collection(db, "auctions");
  const now = new Date().getTime();
  const q = query(auctionsRef, where("finishTime", '>', now))
  try {
    const querySnapshot = await getDocs(q);
    return querySnapshot;
  } catch (error) {
    return { success: 'no', message: error.message }
  }
}
//

//get all offers from an auction
export const getAllOffers = async (id) => {
  if (id === null) {
    return;
  }
  const offerCollectionRef = collection(db, `auctions/${id}/offers`);
  const q = query(offerCollectionRef, orderBy("amount", "desc"));
  try {
    const offersSnapshot = await getDocs(q);
    return offersSnapshot;
  } catch (error) {
    return { success: "no", message: error.message };
  }
};
//

// delete an Auction
export const deleteAuction = async (plate) => {
  if (!plate) {
    console.error("Placa no proporcionada");
    return { success: "no", message: "Placa no proporcionada" };
  }
  try {
    console.log("Iniciando eliminación de la subasta:", plate);
    const auctionRef = doc(db, "auctions", plate);
    const auctionSnapshot = await getDoc(auctionRef);

    if (!auctionSnapshot.exists()) {
      throw new Error("Subasta no encontrada");
    }

    const auctionData = auctionSnapshot.data();
    console.log("Datos de subasta obtenidos:", auctionData);

    // Paso 1: Eliminar ofertas de usuarios
    const offersRef = collection(auctionRef, "offers");
    const offersSnapshot = await getDocs(offersRef);
    console.log("Ofertas obtenidas:", offersSnapshot.docs.length);

    for (const offer of offersSnapshot.docs) {
      const userEmail = offer.data().userEmail;
      const userRef = doc(db, "users", userEmail);
      const userOfferRef = doc(userRef, "offers", plate);
      await deleteDoc(userOfferRef);
      console.log("Oferta eliminada del usuario:", userEmail);
    }

    // Paso 2: Eliminar imágenes
    const frontRef = ref(storage, auctionData.frontURL);
    await deleteObject(frontRef);
    console.log("Imagen frontal eliminada:", auctionData.frontURL);

    const deleteImages = async (imagesArray) => {
      if (Array.isArray(imagesArray)) {
        const deletePromises = imagesArray.map((imageURL) => {
          const imageRef = ref(storage, imageURL);
          return deleteObject(imageRef);
        });
        await Promise.all(deletePromises);
      }
    };

    if (Array.isArray(auctionData.picturesURL)) {
      await deleteImages(auctionData.picturesURL);
    }
    
    if (Array.isArray(auctionData.appraisingURL)) {
      await deleteImages(auctionData.appraisingURL);
    }

    // Paso 3: Eliminar subasta
    await deleteDoc(auctionRef);
    console.log("Subasta eliminada:", plate);

    return { success: "yes", message: "Subasta eliminada" };
  } catch (error) {
    console.error("Error al eliminar subasta:", error);
    return { success: "no", message: error.message };
  }
};