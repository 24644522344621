import { createContext, useState } from "react";

export const UIContext = createContext({
  loading: false,
  setLoading: () => {},
});

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const value = { loading, setLoading };

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};
