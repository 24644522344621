import { useNavigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import NewUserForm from "../../components/new-user-form/add-user-form.component";
import { AuthContext } from "../../context/user.context";

const NewUser = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser === null) {
      navigate("/sign-in");
    }
  }, [currentUser, navigate]);

  return <div>{currentUser && <NewUserForm />}</div>;
};

export default NewUser;
