import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import AlertTitle from "@mui/material/AlertTitle";
import { CircularProgress } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { IconButton } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import { useContext, useState } from "react";
import {
  createNewAuction,
  sendNotifications,
  getUsersPushToken,
} from "../../utils/firebase.utils";
import AuctionEndTimePicker from "../date-picker/TimePicker"

import { AuthContext } from "../../context/user.context";
import { UIContext } from "../../context/UI.context";

const NewAuctionForm = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const { loading, setLoading } = useContext(UIContext);
  const [pictureLoading, setPictureLoading] = useState(false)
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [subModel, setSubModel] = useState("");
  const [year, setYear] = useState("");
  const [kms, setKms] = useState("");
  const [plate, setPlate] = useState("");
  const [location, setLocation] = useState("");
  const [price, setPrice] = useState("");
  const [front, setFront] = useState("");
  const [pictures, setPictures] = useState([]);
  const [apprasing, setApprasing] = useState([]);
  const [result, setResult] = useState({ success: "" });
  const [auctionEndTime, setAuctionEndTime] = useState();

  const formatedPrice = Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
    maximumSignificantDigits: 4,
  }).format(price);

  const formatedPlate = plate.toUpperCase();
  const formatedKms = Intl.NumberFormat("es-AR").format(kms);

  const resetFormFields = () => {
    setBrand("");
    setModel("");
    setSubModel("");
    setYear("");
    setKms("");
    setPlate("");
    setLocation("");
    setPrice("");
    setFront("");
    setPictures([]);
    setApprasing([]);
    setAuctionEndTime("");
  };

  const handleBrandChange = (event) => {
    setBrand(event.target.value);
  };
  const handleModelChange = (event) => {
    setModel(event.target.value);
  };
  const handleSubModelChange = (event) => {
    setSubModel(event.target.value);
  };
  const handleYearChange = (event) => {
    setYear(event.target.value);
  };
  const handleKmsChange = (event) => {
    setKms(event.target.value);
  };
  const handlePlateChange = (event) => {
    setPlate(event.target.value);
  };
  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };
  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };
  const handleFrontChange = (event) => {
    event.preventDefault();
    setResult({ success: "" })
    const newImage = event.target.files[0];
    if (!newImage) return;

    setPictureLoading(true);
    try{
      if (newImage.size < 1048460) {
        newImage["id"] = Math.random();
        setFront(newImage);
      } else {
        setResult({
          success: "no",
          message: "la imagen supera el tamaño máximo permitido 1.024 KB",
        });
        setPictureLoading(false); // Desactiva el spinner en caso de error
        return; // Detiene la ejecución para evitar que se procesen más imágenes
    }    
      
    } catch (error){
      console.error('Error al procesar las imágenes:', error);
      // Manejo de errores
    } finally {
      setPictureLoading(false); // Desactiva el spinner
    }
      
  };
  const handlePictureChange = async (event) => {
    event.preventDefault();
    setResult({ success: "" });
    const files = event.target.files;
    if (files.length === 0) return; // Si no hay archivos seleccionados, no hacer nada

    setPictureLoading(true); // Activa el spinner

    try {
      const newArray = [];
      for (let i = 0; i < files.length; i++) {
        const image = files[i];
        if (image.size < 1048460) {
          image["id"] = Math.random();
          newArray.push(image);
        } else {
          setResult({
            success: "no",
            message: "una o más imagenes supera el tamaño máximo permitido 1.024 KB",
          });
          return; // Si un archivo es demasiado grande, detener el procesamiento
        }
      }
      setPictures(newArray);
    } catch (error) {
      console.error('Error al procesar las imágenes:', error);
      // Manejo de errores
    } finally {
      setPictureLoading(false); // Desactiva el spinner
    }
  };
  const handleApprasingChange = (event) => {
    event.preventDefault();
    setResult({ success: "" });
    const files = event.target.files;
    if (files.length === 0) return; // Si no hay archivos seleccionados, no hacer nada

    setPictureLoading(true); // Activa el spinner

    try {
      const newArray = [];
      for (let i = 0; i < files.length; i++) {
        const image = files[i];
        if (image.size < 1048460) {
          image["id"] = Math.random();
          newArray.push(image);
        } else {
          setResult({
            success: "no",
            message: "una o más imagenes supera el tamaño máximo permitido 1.024 KB",
          });
          return; // Si un archivo es demasiado grande, detener el procesamiento
        }
      }
      setApprasing(newArray);
    } catch (error) {
      console.error('Error al procesar las imágenes:', error);
      // Manejo de errores
    } finally {
      setPictureLoading(false); // Desactiva el spinner
    }
  };
  const handleFrontDelete = () => {
    setResult({ success: "" })
    setFront("");
  };
  const handleImagesDelete = (pic) => {
    setResult({ success: "" })
    let newArray = [];
    newArray = pictures.filter((picture) => {
      return picture !== pic;
    });
    setPictures(newArray);
  };
  const handleApprasingDelete = (pic) => {
    setResult({ success: "" })
    let newArray = [];
    newArray = apprasing.filter((picture) => {
      return picture !== pic;
    });
    setApprasing(newArray);
  };
  const handleSubmit = async (event) => {
    const formInfo = {
      brand,
      model,
      subModel,
      year,
      kms,
      plate: formatedPlate,
      location,
      dealership: currentUser.dealership,
      price,
      front,
      pictures,
      apprasing,
      auctionEndTime,
    };
    event.preventDefault();
    console.log(formInfo)
    
    setResult({ success: "" })
    setLoading(true);
    if (pictures.length === 0 || apprasing.length === 0 || front === "") {
      setResult({
        success: "no",
        message:
          "debe cargarse al menos una foto en los campos de imagen de portada, imagenes y peritaje",
      });
      setLoading(false);
    } else {
      const response = await createNewAuction(formInfo);
      if (response.success === "yes") {
        setResult({ success: "yes", message: response.message });
        const pushTokens = await getUsersPushToken();
        sendNotifications(pushTokens);
        setLoading(false);
        resetFormFields();
        navigate("/");
      } else {
        setResult({ success: "no", message: response.message });
        setLoading(false);
      }
    }
  };
  const handleDateTimeSelect = (unixTime) => {
    setAuctionEndTime(unixTime);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" color="#007ca2" sx={{ mb: 3 }}>
          Nueva Subasta
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <TextField
                label={`Marca: ${brand}`}
                type="text"
                required
                onChange={handleBrandChange}
                name="brand"
                value={brand}
                fullWidth
                id="brand"
                autoFocus
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                label={`Modelo: ${model}`}
                type="text"
                required
                name="model"
                value={model}
                fullWidth
                id="brand"
                onChange={handleModelChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={`Sub modelo: ${subModel}`}
                type="text"
                required
                onChange={handleSubModelChange}
                name="subModel"
                value={subModel}
                fullWidth
                id="subModel"
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextField
                label={`Año: ${year}`}
                type="text"
                required
                onChange={handleYearChange}
                name="year"
                value={year}
                fullWidth
                id="year"
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextField
                label={`Kilometros: ${formatedKms}`}
                type="number"
                required
                onChange={handleKmsChange}
                name="kms"
                value={kms}
                fullWidth
                id="kms"
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextField
                label={`Patente: ${formatedPlate}`}
                type="text"
                required
                onChange={handlePlateChange}
                name="plate"
                value={plate}
                fullWidth
                id="plate"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label={"Ubicación"}
                type="text"
                required
                onChange={handleLocationChange}
                name="location"
                value={location}
                fullWidth
                id="location"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={`Precio: ${formatedPrice}`}
                type="number"
                required
                onChange={handlePriceChange}
                name="price"
                value={price}
                fullWidth
                id="price"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="input-label" id="finish-hours">
                Final en
              </InputLabel>
              <AuctionEndTimePicker onDateTimeSelect={handleDateTimeSelect} />
            </Grid>
            <Grid item xs={12}>
              <label className="file-input-label" htmlFor="frontImage">
                Imagen de portada
                <IconButton color="primary" component="span">
                  {pictureLoading ? <CircularProgress color="primary" size={20} /> : <PhotoCameraIcon />}
                </IconButton>
              </label>
              <input
                id="frontImage"
                className="file-input"
                type="file"
                accept="image/*"
                required
                onChange={handleFrontChange}
                name="front"
                hidden
              />
              {front !== "" && (
                <Stack direction="row" spacing={0}>
                  <Avatar
                    alt="front"
                    src={URL.createObjectURL(front)}
                    sx={{ width: 60, height: 60 }}
                  />
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={handleFrontDelete}
                  >
                    <DoDisturbOnIcon />
                  </IconButton>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12}>
              <label className="file-input-label" htmlFor="images">
                Imagenes
                <IconButton color="primary" component="span">
                  {pictureLoading ? <CircularProgress color="primary" size={20} /> : <PhotoCameraIcon />}
                </IconButton>
              </label>
              <input
                id="images"
                className="file-input"
                type="file"
                accept="image/*"
                multiple
                required
                onChange={handlePictureChange}
                name="pictures"
                hidden
              />
              {pictures !== "" && (
                <Stack direction="row" spacing={1} flex="1">
                  {pictures.map((pic) => {
                    return (
                      <Stack direction="row" spacing={0} key={pic.name}>
                        <Avatar
                          src={URL.createObjectURL(pic)}
                          alt={`${pic.name}`}
                          sx={{ width: 50, height: 50 }}
                        />
                        <IconButton
                          color="primary"
                          component="span"
                          onClick={() => handleImagesDelete(pic)}
                        >
                          <DoDisturbOnIcon />
                        </IconButton>
                      </Stack>
                    );
                  })}
                </Stack>
              )}
            </Grid>
            <Grid item xs={12}>
              <label className="file-input-label" htmlFor="apprasing">
                Peritaje
                <IconButton color="primary" component="span">
                  {pictureLoading ? <CircularProgress size={20}  color="primary" /> : <PhotoCameraIcon />}
                </IconButton>
              </label>
              <input
                id="apprasing"
                label="Peritaje"
                className="file-input"
                type="file"
                multiple
                required
                accept="image/*"
                onChange={handleApprasingChange}
                name="apprasing"
                hidden
              />
              {pictureLoading && <CircularProgress color="primary" size="small" />}
              {apprasing !== "" && (
                <Stack direction="row" spacing={2}>
                  {apprasing.map((app) => {
                    return (
                      <Stack direction="row" spacing={0} key={app.name}>
                        <Avatar
                          src={URL.createObjectURL(app)}
                          alt={`${app.name}`}
                          sx={{ width: 60, height: 60 }}
                        />
                        <IconButton
                          color="primary"
                          component="span"
                          onClick={() => handleApprasingDelete(app)}
                        >
                          <DoDisturbOnIcon />
                        </IconButton>
                      </Stack>
                    );
                  })}
                </Stack>
              )}
            </Grid>
          </Grid>

          {loading && <CircularProgress color="primary" sx={{ mt: 3, mb: 3 }} />}
          {result.success === "no" && (
            <Alert severity="error">
              <AlertTitle>Se produjo un error</AlertTitle>
              <strong>{result.message}</strong>
            </Alert>
          )}
          {result.success === "yes" && (
            <Alert
              action={
                <Button
                  color="inherit"
                  size="medium"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Volver
                </Button>
              }
            >
              Subasta creada con éxito
            </Alert>
          )}
          {!loading && (
            <Button
              sx={{ mt: 3, mb: 10 }}
              variant="contained"
              type="submit"
              fullWidth
            >
              Crear
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default NewAuctionForm;
