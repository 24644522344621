import React, { useContext, useState, useEffect } from "react";
import { modifyAuction, deleteAuction } from "../../utils/firebase.utils";

import { UIContext } from "../../context/UI.context";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { CircularProgress } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";

const ModifyAuctionForm = ({ auctionData }) => {
  const navigate = useNavigate();
  const { loading, setLoading } = useContext(UIContext);
  const [brand, setBrand] = useState();
  const [model, setModel] = useState();
  const [subModel, setSubModel] = useState();
  const [year, setYear] = useState();
  const [kms, setKms] = useState();
  const [location, setLocation] = useState();
  const [date, setDate] = useState(1);
  const [result, setResult] = useState({ success: "" });
  const [ActiveAlert, setActiveAlert] = useState(false);
  const formatedKms = Intl.NumberFormat("es-AR").format(kms);
  const [frontImage, setFrontImage] = useState(null);
  const [pictures, setPictures] = useState([]);
  const [appraisingImages, setAppraisingImages] = useState([]);
  
  const handleImageChange = (event, setImageState) => {
    if (event.target.files.length === 1) {
      setImageState(event.target.files[0]);
    } else {
      setImageState(Array.from(event.target.files));
    }
  };

  useEffect(() => {
    if (auctionData) {
      setBrand(auctionData.brand);
      setModel(auctionData.model);
      setSubModel(auctionData.subModel);
      setYear(auctionData.year);
      setKms(auctionData.kms);
      setLocation(auctionData.location);
    } else {
      setBrand("");
      setModel("");
      setSubModel("");
      setYear("");
      setKms("");
    }
  }, [auctionData]);

  const handleBrandChange = (event) => {
    setBrand(event.target.value);
  };
  const handleModelChange = (event) => {
    setModel(event.target.value);
  };
  const handleSubModelChange = (event) => {
    setSubModel(event.target.value);
  };
  const handleYearChange = (event) => {
    setYear(event.target.value);
  };
  const handleKmsChange = (event) => {
    setKms(event.target.value);
  };
  const handleLocationChange = (event) => {
    setLocation(event.target.value)
  };
  const handleAddTime = (event) => {
    setDate(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formInfo = {
      brand,
      plate: auctionData.plate,
      model,
      subModel,
      year,
      dealership: auctionData.dealership,
      kms,
      location,
      finish: auctionData.finishTime,
      date,
      front: frontImage,
      pictures,
      apprasing: appraisingImages,
    };
    const response = await modifyAuction(formInfo);
    setResult({ success: response.success, message: response.message });
    setLoading(false);
    if (response.success === "yes") {
      navigate("/");
    } else {
      setActiveAlert(true)
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteAuction(auctionData.plate);
      navigate("/");
    } catch (error) {
      console.error("Error al eliminar la subasta:", error);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" color="#007ca2">
          Modificar Subasta
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            mt: 3,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            width: 600,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <TextField
                label={`Marca: ${brand}`}
                type="text"
                required
                onChange={handleBrandChange}
                name="brand"
                value={brand}
                fullWidth
                id="brand"
                autoFocus
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                label={`Modelo: ${model}`}
                type="text"
                required
                name="model"
                value={model}
                fullWidth
                id="brand"
                onChange={handleModelChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={`Sub modelo: ${subModel}`}
                type="text"
                required
                onChange={handleSubModelChange}
                name="subModel"
                value={subModel}
                fullWidth
                id="subModel"
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                label={`Año: ${year}`}
                type="text"
                required
                onChange={handleYearChange}
                name="year"
                value={year}
                fullWidth
                id="year"
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                label={`Kilometros: ${formatedKms}`}
                type="number"
                required
                onChange={handleKmsChange}
                name="kms"
                value={kms}
                fullWidth
                id="kms"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label={'Ubicación'}
                type="text"
                required
                onChange={handleLocationChange}
                name="location"
                value={location}
                fullWidth
                id="location"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="input-label" id="finish-hours">
                Agregar tiempo
              </InputLabel>
              <Select
                className="input-label"
                labelId="finish-hours"
                required
                fullWidth
                id="hours-select"
                value={date}
                label="Finaliza en"
                onChange={handleAddTime}
              >
                <MenuItem value={0}>No sumar nada</MenuItem>
                <MenuItem value={1}>1 hora</MenuItem>
                <MenuItem value={2}>2 horas</MenuItem>
                <MenuItem value={3}>3 horas</MenuItem>
                <MenuItem value={4}>4 horas</MenuItem>
                <MenuItem value={6}>6 horas</MenuItem>
                <MenuItem value={8}>8 horas</MenuItem>
                <MenuItem value={12}>12 horas</MenuItem>
                <MenuItem value={24}>24 horas</MenuItem>
              </Select>
            </Grid>
            <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel htmlFor="front-image">Imagen Frontal</InputLabel>
          <Input
            id="front-image"
            name="frontImage"
            type="file"
            onChange={(e) => handleImageChange(e, setFrontImage)}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="pictures">Imágenes Adicionales</InputLabel>
          <Input
            id="pictures"
            name="pictures"
            type="file"
            inputProps={{ multiple: true }}
            onChange={(e) => handleImageChange(e, setPictures)}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="appraising-images">Imágenes de Tasación</InputLabel>
          <Input
            id="appraising-images"
            name="appraisingImages"
            type="file"
            inputProps={{ multiple: true }}
            onChange={(e) => handleImageChange(e, setAppraisingImages)}
          />
        </Grid>
      </Grid>
          </Grid>
          <Box
            sx={{
              mt: 5,
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {loading && <CircularProgress color="primary" />}
            {result.success === "no" && (
              <Alert severity="error">
                <AlertTitle>Se produjo un error</AlertTitle>
                <strong>{result.error}</strong>
              </Alert>
            )}
            {ActiveAlert && (
              <Alert
                action={
                  <Button color="inherit" size="medium" onClick={handleDelete}>
                    Aceptar
                  </Button>
                }
              >
                ¿Esta seguro que desea eliminar por completo la subasta? esta
                acción no se puede deshacer
              </Alert>
            )}
            {!loading && (
              <Button
                sx={{ mt: 3, mb: 2 }}
                variant="contained"
                type="submit"
                fullWidth
              >
                Modificar
              </Button>
            )}
            {!loading && (
              <Button
                sx={{ mt: 3, mb: 2 }}
                variant="contained"
                onClick={() => {
                  setActiveAlert(true);
                }}
                fullWidth
              >
                Eliminar Subasta
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ModifyAuctionForm;
