import { Routes, Route } from "react-router-dom";

import Home from "./routes/home/home.component";
import NewAuction from "./routes/new-auction/new-auction.component";
import ModifyAuction from "./routes/modify-auction/modify-auction.component";
import NewUser from "./routes/new-user/new-user.component";
import AuctionList from "./routes/auction-list/auction-list.component";
import SignIn from "./routes/sign-in/sign-in.component";
import AuctionOffers from "./routes/auction-offers/auction-offers.component";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#007ca2",
    },
    secondary: {
      main: "#11cb5f",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="sign-in" element={<SignIn />} />
        <Route path="/" element={<Home />}>
          <Route path="/new-auction" element={<NewAuction />} />
          <Route path="/new-user" element={<NewUser />} />
          <Route path="/modify-auction/:plate" element={<ModifyAuction />} />
          <Route index element={<AuctionList />} />
          <Route path="/auction-offers/:plate" element={<AuctionOffers />} />
          <Route path="*" element={<h1>Nada por aquí, regrese.</h1>} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
};

export default App;
